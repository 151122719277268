<template>
  <el-dialog
    :title="`${mpName}种子人群包`"
    append-to-body
    :visible.sync="isShowFeedCrowd"
    :before-close="handleClose"
    width="950px"
    top="2vh"
  >
    <!-- 人群包选择组件测试 -->
    <div class="adui-dialog-inner">
      <div class="adui-dialog-header">
        <h3>选择种子人群</h3>
      </div>
      <div class="adui-dialog-body">
        <div class="body-left">
          <div class="dump-input">
            <el-input
              v-model="search"
              placeholder="输入名称搜索人群包"
              style="width:430px"
            >
              <el-button slot="append" icon="el-icon-search"></el-button>
            </el-input>
          </div>
          <div class="dump-container">
            <div class="dump-table-thread dump-table-th">
              <div class="check-status table-th" @click="chooseCurPageAll">
                <i
                  class="iconfont icon-xuanze"
                  v-if="checkChooseStatus"
                ></i>
                <i class="iconfont icon-xuanze2-copy " v-else></i>
              </div>
              <div class="crowd-name table-th">
                <span style="font-weight:bold">人群名称</span>
              </div>
              <div class="crowd-cover-num table-th">
                <span style="font-weight:bold">预计覆盖人数</span>
              </div>
              <div class="crowd-status table-th">
                <span style="font-weight:bold">状态</span>
              </div>
            </div>
            <div
              class="dump-table-th"
              v-for="(item, index) in myCrowdInfo.slice(
                (curPage - 1) * 10,
                curPage * 10
              )"
              :key="index"
            >
              <div class="check-status table-th">
                <i v-if="item.disabled" class="iconfont icon-forbidden-copy">
                </i>
                <i
                  v-if="!item.checkStatus && !item.disabled"
                  class="iconfont icon-xuanze"
                  style="cursor:pointer"
                  @click="changeStatus(item)"
                >
                </i>
                <i
                  v-if="item.checkStatus && !item.disabled"
                  class="iconfont icon-xuanze2-copy"
                  style="cursor:pointer;color:#07C160"
                  @click="changeStatus(item)"
                ></i>
              </div>
              <div class="crowd-name table-th">
                {{ item.name }}
              </div>
              <div class="crowd-cover-num table-th">
                <span>{{ item.user_count }}</span>
              </div>
              <div class="crowd-status table-th">
                <span>{{
                  item.status === "SUCCESS" ? "已生效" : "冻结中"
                }}</span>
              </div>
            </div>
            <el-pagination
              layout="prev, pager, next"
              :total="myCrowdInfo.length"
              @current-change="handleCurrentPage"
            >
            </el-pagination>
          </div>
        </div>

        <div class="body-right">
          <div class="container">
            <div class="body-right-header">
              <span>
                已选
                <span style="color:rgb(7, 193, 96)">{{
                  copyMyCrowdInfo.filter(item => item.checkStatus ).length
                }}</span>
                <span style="color:rgb(163, 163, 163)">/200</span>
                <span>个人群，</span>
                <span>预计覆盖</span>
                <span style="color:rgb(7, 193, 96)">{{
                  totalNum | formate
                }}</span>
                <span>人</span>
              </span>
            </div>
            <div class="my-choose-crowd">
              <p>我的人群</p>
              <div class="choose-list">
                <div class="choose-crowd-wrapper">
                  <div
                    class="choose-item"
                    v-for="(item, index) in copyMyCrowdInfo.filter(
                      item => item.checkStatus && !item.disabled
                    )"
                    :key="index"
                  >
                    <span>{{ item.name }}</span>
                    <i
                      class="iconfont icon-shanchu1"
                      @click="delChooseItem(item)"
                    ></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="button-footer">
            <el-button type="info" @click="handleCancel">取消</el-button>
            <el-button type="primary" @click="handleConfirm">确定</el-button>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="button-operation">
      <el-button type="info" @click="handleCancel" size="large">取消</el-button>
      <el-button type="primary" @click="handleConfirm" size="large"
        >保存</el-button
      >
    </div> -->
  </el-dialog>
</template>

<script>
export default {
  props: [
    'crowdInfo',
    'isShowFeedCrowd',
    'mpName',
    'echoList',
    'type',
    'chooseChannelList',
    'curIndex'
  ],
  data () {
    return {
      search: '',
      curPage: 1,
      chooseCrowd: [],
      myCrowdInfo: [],
      copyMyCrowdInfo: [], // 创建一个myCrowdInfo 方便输入框的筛选操作
      totalNum: 0
    }
  },
  filters: {
    formate (num) {
      const res = num.toString().replace(/\d+/, function (n) {
        // 先提取整数部分
        return n.replace(/(\d)(?=(\d{3})+$)/g, function ($1) {
          return $1 + ','
        })
      })
      return res
    }
  },
  watch: {
    copyMyCrowdInfo: {
      handler (newV) {
        this.totalNum = newV.reduce((acc, b, c) => {
          if (b.checkStatus) {
            acc = acc + b.user_count
          }
          return acc
        }, 0)
      },
      deep: true
    },
    search: {
      handler (newV) {
        this.curPage = 1
        this.myCrowdInfo = this.copyMyCrowdInfo.filter(item => {
          return item.name.includes(newV)
        })
      }
    }
  },
  mounted () {
    this.initData()
  },
  methods: {
    initData () {
      const list = JSON.parse(JSON.stringify(this.crowdInfo)).map(item => {
        return {
          ...item,
          checkStatus: false,
          disabled: false
        }
      })
      // 排除已经选择的种子人群
      const alreadyChooseItem = this.chooseChannelList[this.curIndex]
        .chooseFeedCrowd
      list.forEach(item => {
        if (alreadyChooseItem.some(i => i.audience_id === item.audience_id)) {
          item.checkStatus = true
        }
      })
      this.myCrowdInfo = this.deepClone(list)
      this.copyMyCrowdInfo = this.deepClone(list)
    },
    handleCancel () {
      this.$emit('update:isShowFeedCrowd', false)
    },
    handleConfirm () {
      const res = this.myCrowdInfo.filter(
        item => item.checkStatus === true && !item.disabled
      )
      res.forEach(item => {
        // !删除这两个属性排除回显时候的干扰
        delete item.checkStatus
        delete item.disabled
      })
      this.$emit('success', res)
      this.$emit('update:isShowFeedCrowd', false)
    },
    handleClose () {
      this.$emit('update:isShowFeedCrowd', false)
    },
    handleCurrentPage (page) {
      this.curPage = page
    },
    changeStatus (item) {
      const flag = !item.checkStatus
      const index = this.myCrowdInfo.findIndex(crowd => crowd.audience_id === item.audience_id)
      const obj = {
        ...item,
        checkStatus: flag
      }
      // 修改筛选后点击单个人群包无法生效的bug
      this.$set(this.myCrowdInfo, index, obj)
      const targetIndex = this.copyMyCrowdInfo.findIndex(crowd => crowd.audience_id === item.audience_id)
      if (targetIndex > -1) {
        this.copyMyCrowdInfo[targetIndex].checkStatus = !this.copyMyCrowdInfo[targetIndex].checkStatus
      }
    },
    delChooseItem (item) {
      item.checkStatus = false
      const targetIndex = this.myCrowdInfo.findIndex(crowd => crowd.audience_id === item.audience_id)
      this.myCrowdInfo[targetIndex].checkStatus = false
    },
    chooseCurPageAll () {
      const targetCrowdInfo = this.myCrowdInfo.filter((item, index) => {
        return index >= (this.curPage - 1) * 10 && index < this.curPage * 10
      })
      // 是否全选
      const isAllSelected = targetCrowdInfo.every(item => item.checkStatus)
      for (let i = (this.curPage - 1) * 10; i < this.curPage * 10; i++) {
        if (this.myCrowdInfo[i]) {
          const targetIndex = this.copyMyCrowdInfo.findIndex(item => item.audience_id === this.myCrowdInfo[i].audience_id)
          if (!isAllSelected) {
            this.myCrowdInfo[i].checkStatus = true
            this.copyMyCrowdInfo[targetIndex].checkStatus = true
          } else {
            this.myCrowdInfo[i].checkStatus = false
            this.copyMyCrowdInfo[targetIndex].checkStatus = false
          }
        }
      }
    },
    deepClone (val) {
      return JSON.parse(JSON.stringify(val))
    },
    checkChooseStatus () {}
  }
}
</script>

<style lang="scss" scoped>
/deep/.el-dialog__body {
  padding-top: 0;
  padding-bottom: 0;
}
.iconfont{
  font-size:16px;
}
.adui-dialog-inner {
  font-family: PingFangSC-Regular, sans-serif;
  background: #fff;
  // margin-top: 30px;
  border-bottom: 1px solid #fafafa;
  .adui-dialog-header {
    padding-bottom: 0px;
    box-shadow: rgba(0, 0, 0, 0.06) 0px 1px 0px 0px;
    box-sizing: border-box;
    padding: 20px;
  }
  .adui-dialog-body {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #353535;
    overflow: hidden;
    height: 670px;
    .body-left {
      border-right: 1px solid #ededed;
      padding-top: 30px;
      height: 100%;
      .dump-input {
      }
      .dump-container {
        .dump-table-th {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          text-align: left;
          padding: 15px;
          border-bottom: 1px solid #ededed;
          .table-th {
            width: 120px;
            padding: 0 10px;
          }
          .check-status {
            width: 30px;
            .icon-forbidden-copy {
              color: #ccc;
            }
          }
          .crowd-name {
            width: 200px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }
    }
    .body-right {
      background: #fafafa;
      align-self: flex-start;
      height: 100%;
      flex-grow: 1;
      .container {
        overflow: auto;
        height: 591px;
        padding: 28px 28px 10px;
        border-bottom: 1px solid #ededed;
        .body-right-header {
          font-weight: bold;
        }
        .my-choose-crowd {
          > p {
            margin: 10px 0;
            font-size: 14px;
            color: rgb(163, 163, 163);
          }
          .choose-list {
            .choose-crowd-wrapper {
              // overflow: auto;
              .choose-item {
                display: flex;
                // font-size: 13px;
                -ms-flex-pack: justify;
                justify-content: space-between;
                -ms-flex-align: center;
                align-items: center;
                padding: 8px 8px 8px 12px;
                margin-bottom: 8px;
                background: #fff;
                // border:1px solid rgba(0,0,0,.04)
                box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.04),
                  0 0 0 1px hsla(0, 0%, 87%, 0.5);
                border-radius: 4px;
                i {
                  color: #c2c2c2;
                }
                &:hover {
                  i {
                    color: #000;
                  }
                }
              }
            }
          }
        }
      }
      .button-footer {
        margin-top: 20px;
        text-align: right;
        padding-right: 28px;
      }
    }
  }
}
</style>
