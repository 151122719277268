<template>
  <el-drawer
    title="批量添加地域"
    size="700px"
    append-to-body
    :visible.sync="isBatchAdd"
    :before-close="handleClose"
  >
    <div class="add-title">
      <el-input
        v-model="title"
        placeholder="请为当前的地域创建一个标题!"
      ></el-input>
      <i
        class="iconfont icon-tishi"
        style="margin-left:5px;font-size:16px"
        title="粘贴之前请确保内容格式符合要求!
--请在诸如‘上海’，‘天津’，‘重庆’等后面加上“市”"
      ></i>
      <span style="font-size:12px;color:red;margin-left:10px"
        >请从excel当中复制地域内容！</span
      >
    </div>
    <div class="box" v-loading="isLoading">
      <div class="tips" v-if="totalCityNum">
        <span
          >已成功添加<span style="color: #63c168;">{{ totalCityNum }}</span
          >个地域</span
        >
        <span @click="clearInnerHTML">清空</span>
      </div>
      <div class="custom-placeholder" v-if="!isPasted">
        可以输入粘贴好的城市列表，进行批量添加。
      </div>
      <div
        class="batch-regions"
        id="batch-regions"
        ref="batchRegions"
        contenteditable="true"
      ></div>
    </div>
    <div class="not-distinguish" v-if="notInRegion.length > 0">
      <p>以下地域添加失败</p>
      <p>
        无法识别：<span>{{ notInRegion | formatRegion }}</span>
      </p>
    </div>
    <div class="button-list">
      <el-button @click="handleClose">取消</el-button>
      <el-button @click="comfirm" type="primary" :loading="confirmLoading"
        >确认</el-button
      >
    </div>
  </el-drawer>
</template>
<script>
import {
  addRegionHistory,
  // getRegionHistory,
  checkRegionList
} from '@/api/novelPut'

export default {
  name: 'batchAddRegion',
  props: ['isBatchAdd'],
  data () {
    return {
      str: '',
      totalCityNum: 0,
      isLoading: false,
      dialogVisible: false,
      isPasted: false, // 是否进行了粘贴的操作
      title: '',
      confirmLoading: false,
      regionArr: [],
      exitRegion: [], // 符合格式的地域
      notInRegion: [] // 不符合格式的地域
    }
  },
  filters: {
    formatRegion (val) {
      const res = val.filter(item => !!item)
      if (res.length === 0) {
        return '空格'
      }
      return res.join('|')
    }
  },
  watch: {
    isBatchAdd: {
      handler (newV) {
        if (newV) {
          this.initEvent()
        }
      }
    },
    isPasted: {
      handler (newV) {
        const dom = document.getElementById('batch-regions')
        if (newV) {
          // !粘贴之后禁止用户输入
          this.$nextTick(() => {
            dom.onkeypress = function (e) {
              e.preventDefault()
            }
          })
        } else {
          this.$nextTick(() => {
            dom.onkeypress = null
          })
        }
      }
    }
  },
  methods: {
    initEvent () {
      this.$nextTick(() => {
        const batchRegion = document.getElementById('batch-regions')
        batchRegion.innerHTML = ''
        batchRegion.onpaste = e => {
          e.stopPropagation()
          e.preventDefault()
          const clipboardData = e.clipboardData || window.clipboardData
          const pastedData = clipboardData.getData('Text')

          this.regionArr = pastedData.split('\n').map(item => {
            item = item.replace(/\r/g, '').trim()
            return item
          })
          this.isLoading = true
          checkRegionList({
            regionNameText: JSON.stringify(this.regionArr)
          }).then(res => {
            // let  = JSON.parse(JSON.stringify(this.exitRegion));
            // !只展示符合条件的地域名称
            this.exitRegion = res.exitRegion
            this.notInRegion = res.notInRegion
            this.totalCityNum = this.exitRegion.length
            this.exitRegion.forEach(item => {
              this.str += `<span style='padding:20px 10px'>${item}</span>`
            })
            document.getElementById('batch-regions').innerHTML = this.str
            this.isLoading = false
            this.isPasted = true
          })
        }
      })
    },
    handleClose () {
      this.$confirm('此操作将清除未保存的内容， 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.resetData()
          this.$emit('update:isBatchAdd', false)
        })
        .catch(() => {})
    },
    handleDialogClose () {
      this.dialogVisible = false
    },
    comfirm () {
      if (!this.regionArr.length) {
        this.$message({
          showClose: true,
          message: '请粘贴地域名称!',
          type: 'error'
        })
        return
      }
      if (!this.title) {
        this.$message({
          showClose: true,
          message: '请输入标题!',
          type: 'error'
        })
        return
      }
      this.confirmLoading = true
      addRegionHistory({
        name: this.title,
        regionNameText: this.exitRegion
      })
        .then(() => {
          this.resetData()
          this.$emit('success')
        })
        .finally(() => {
          this.confirmLoading = false
        })
    },
    clearInnerHTML () {
      this.resetData(false)
    },
    resetData (flag = true) {
      const batchRegion = document.getElementById('batch-regions')
      batchRegion.innerHTML = ''
      if (flag) {
        batchRegion.onpaste = null
      }
      this.str = ''
      this.isPasted = false
      this.totalCityNum = 0
      this.regionArr = []
      this.exitRegion = []
      this.notInRegion = []
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .el-drawer {
  padding: 20px;
  /deep/ .el-drawer__header {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
  }
}
.add-title {
  margin-bottom: 10px;
  .el-input {
    width: 300px;
    margin-left: 30px;
  }
}
.not-distinguish {
  color: #e15f63;
  margin-left: 30px;
  margin-top: 10px;
  font-size: 13px;
  > p {
    margin-bottom: 5px;
  }
}
.button-list {
  text-align: center;
  margin-top: 20px;
}

.box {
  width: 600px;
  height: 700px;
  overflow: auto !important;
  border: 1px solid #e7e7eb;
  margin-left: 30px;
  position: relative;
  .custom-placeholder {
    position: absolute;
    width: 400px;
    top: 4px;
    left: 50%;
    transform: translate(-50%);
    color: #ccc;
  }
  .batch-regions {
    line-height: 2;
    display: flex;
    justify-content: flex-start;
    align-content: center;
    flex-wrap: wrap;
    &:focus {
      outline: none;
    }
  }
  .tips {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 10px;
    > span {
      font-weight: bold;
      color: #ccc;
    }
    > span:nth-of-type(2) {
      cursor: pointer;
      color: #1473cc;
    }
  }
}
</style>
