<template>
<el-drawer
  title='选择原生推广页'
  size='800px'
  append-to-body
  :visible.sync='isShowChoosePage'
  :before-close='handleClose'
>
  <div class="novel-plan-children-choose">
    <div class="operation">
      <el-input
        v-model="search"
        style="width:300px;margin-right:10px"
        placeholder="请输入关键词搜索推广页"
      >
        <el-button
          slot="append"
          icon="el-icon-search"
          @click="handleSearch"
        ></el-button>
      </el-input>
      <div>
        <el-button plain  @click="refreshData"
          >刷新数据</el-button
        >
        <el-button @click="goToPageManage">推广页管理</el-button>
        <el-button @click="goToCreatePage" icon="el-icon-plus"
          >新建推广页</el-button
        >
      </div>
    </div>
    <div class="container">
      <el-table :data="dataList"   height="600px" v-loading="loading"
       @selection-change="handleSelectionChange">
        <el-table-column width="55" type="selection"></el-table-column>
        <el-table-column label="原生推广页" prop="canvasName"></el-table-column>
        <el-table-column label="顶部视频时长">
          <template>
            <span>---</span>
          </template>
        </el-table-column>
        <el-table-column label="适用样式">
          <template>
            <span>适用常规样式</span>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        style="padding: 20px; text-align: right"
        background
        layout="total, prev, pager, next, jumper"
        :page-size="5"
        :total="total"
        @current-change="handlePageChange"
      ></el-pagination>
    </div>
    <div class='button-operation'>
        <el-button type='primary' @click='handleConfirm'>确定</el-button>
    </div>
  </div>
  </el-drawer>
</template>
<script>
import { getWeChatPage } from '@/api/novelPut'
import { mapMutations } from 'vuex'
export default {
  name: 'choosePage',
  props: ['isShowChoosePage'],
  data () {
    return {
      pageSize: 10,
      page: 1,
      search: '',
      dataList: [],
      total: 0,
      loading: true,
      selectionList: []
    }
  },
  watch: {
    isShowChoosePage: {
      handler (newV) {
        if (newV) {
          this.initData()
        }
      },
      immediate: true
    }
  },
  methods: {
    ...mapMutations(['setIsPageUpdate', 'resetAllModule']),
    initData () {
      this.loading = true
      getWeChatPage({
        pageSize: this.pageSize,
        page: this.page,
        search: this.search
      }).then(res => {
        this.total = res.total
        this.dataList = res.list
        this.loading = false
      })
    },
    handleSearch () {
      this.page = 1
      this.initData()
    },
    refreshData () {
      this.search = ''
      this.page = 1
      this.initData()
    },
    goToPageManage () {
      const path = this.$router.resolve({
        name: 'DeliveryMaterialManage'
      })
      window.open(path.href, '_blank')
    },
    goToCreatePage () {
      window.localStorage.removeItem('bgColor')
      this.setIsPageUpdate(false)
      this.resetAllModule()
      const path = this.$router.resolve({
        name: 'PromotionPage'
      })
      window.open(path.href, '_blank')
    },
    handlePageChange (page) {
      this.page = page
      this.initData()
    },
    handleClose () {
      this.selectionList = []
      this.$emit('close')
    },
    handleConfirm () {
      this.$emit('confirm', this.selectionList)
    },
    handleSelectionChange (selection) {
      this.selectionList = selection
    }
  }
}
</script>
<style lang="scss" scoped>
.novel-plan-children-choose {
  .operation {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .container {
    margin-top: 20px;
  }
  .button-operation{
      text-align: center;
  }
}
</style>
