<template>
    <div class='back-up-down' :style='setPosition()'>
      <i class='el-icon-caret-top' @click='goUp' :class="{'is-selected':direction ==='down'}"></i>
      <i class='el-icon-caret-bottom' @click='goDowm' :class="{'is-selected' :direction === 'up'}"></i>
    </div>
</template>
<script>
export default {
  name: 'backUpDown',
  props: ['targetUp', 'targetDown', 'top', 'left'],
  data () {
    return {
      target: null,
      direction: 'up'
    }
  },
  methods: {
    goUp () {
      this.target = this.targetUp
      this.direction = 'up'
      this.scrollTarget()
    },
    goDowm () {
      this.target = this.targetDown
      this.direction = 'down'
      this.scrollTarget()
    },
    // 跳转到制定位置
    scrollTarget () {
      document.querySelector(`${this.target}`).scrollIntoView({
        behavior: 'smooth'
      })
    },
    setPosition () {
      return {
        top: `${this.top || '90%'}`,
        left: `${this.left || '95%'}`,
        marginRight: '300px'
      }
    },
    resetDirection(){
      this.direction='up'
    }
  },
 
}
</script>
<style lang="scss" scoped>
    .back-up-down{
        position: fixed;
        top:90%;
        left:95%;
        background:#fff;
        box-shadow: 0 0 6px rgba(0,0,0,.12);
        z-index:9999;
        width:50px;
        height:50px;
        border-radius:50%;
        display: flex;
        flex-direction:column;
        justify-content: center ;
        align-items: center;
        font-size:20px;
        i{
            color:#ccc;
        }
        .is-selected{
            color:#1c9399;
        }

    }
</style>
