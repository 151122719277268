<template>
  <el-drawer
    title="地域历史记录"
    size="60%"
    append-to-body
    :visible.sync="isShowRegionHistory"
    :before-close="handleClose"
  >
    <div class="region-history">
      <div class="operation">
        <el-input
          v-model="search"
          placeholder="请输入标题!"
          style="width:300px;margin-right:10px"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="handleSearch"
          ></el-button>
        </el-input>
        <el-button type="primary" @click="isBatchAdd = true"
          >新增地域</el-button
        >
        <el-button
          type="danger"
          plain
          @click="delSelectRegion"
          :disabled="rowData.length === 0"
          >批量删除</el-button
        >
      </div>
    </div>
    <div class="region-table">
      <el-table
        :data="regionList"

        style="width: 100%"
        v-loading="loading"
        @select="handleRowSelect"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column type="expand">
          <template slot-scope="scope">
            <div class="region-table-expand">
              <div>
                所选地域
              </div>
              <div class="region-list">
                <span
                  class="region-item"
                  v-for="(item, index) in scope.row.regionNameText
                    .split(',')
                    .slice(0, 100)"
                  :key="index"
                >
                  {{ item }}
                </span>
              </div>
              <div
                class="check-more"
                v-if="scope.row.regionNameText.split(',').length > 100"
              >
                <el-popover placement="right" width="280" trigger="hover">
                  <div class="watch-allCrowd">
                    <span
                      v-for="(item, index) in scope.row.regionNameText.split(
                        ','
                      )"
                      :key="index"
                      >{{ item }}</span
                    >
                  </div>
                  <a
                    class="region-item"
                    href="javascript:;"
                    slot="reference"
                    style="margin-left:5px"
                    >查看全部</a
                  >
                </el-popover>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="ID" prop="id" width="70"></el-table-column>
        <el-table-column prop="name" label="名称" width="150"></el-table-column>
        <el-table-column label="地域">
          <template slot-scope="scope">
            <div class="regions">
              {{ scope.row.regionNameText }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="创建时间" width="200">
          <template slot-scope="scope">
            <span>{{ scope.row.createdTime | formatTime }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="200">
          <template slot-scope="scope">
             <el-button type="primary" class='button-small' size="mini" @click="handleOk(scope.row)">选择</el-button>
            <el-button type="danger" class='button-small' size="mini" @click="delRegion(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        style="padding: 20px; text-align: right"
        background
        layout="total, prev, pager, next, jumper"
        :page-size="pageSize"
        :total="total"
        @current-change="handlePageChange"
      ></el-pagination>
    </div>
    <batch-add-region
      :isBatchAdd.sync="isBatchAdd"
      @success="handleSuccess"
    ></batch-add-region>
  </el-drawer>
</template>

<script>
import {
  // addRegionHistory,
  getRegionHistory,
  delRegionHistory
} from '@/api/novelPut'
import batchAddRegion from './../components/batch-add-region'
import { formatTime } from '@/assets/js/utils'
export default {
  name: 'batchAddRegionHistory',
  props: ['isShowRegionHistory'],
  data () {
    return {
      pageSize: 10,
      search: '',
      regionList: [],
      isBatchAdd: false,
      total: 0,
      page: 1,
      loading: false,
      rowData: []
    }
  },
  filters: {
    formatTime (val) {
      return formatTime(new Date(val))
    },
    formatRegion (val) {}
  },
  watch: {
    isShowRegionHistory: {
      handler (newV) {
        if (newV) {
          this.getRegionHistoryList()
        }
      }
    }
  },
  methods: {
    handleClose () {
      this.resetData()
      this.$emit('update:isShowRegionHistory', false)
    },
    getRegionHistoryList () {
      this.loading = true
      getRegionHistory({
        pageSize: 10,
        search: this.search.trim()
      })
        .then(res => {
          this.regionList = res.list
          this.total = res.total
        })
        .finally(() => {
          this.loading = false
        })
    },
    handlePageChange (val) {
      this.page = val
      this.getRegionHistoryList()
    },
    handleSearch () {
      this.getRegionHistoryList()
    },
    handleRowSelect (val) {
      this.rowData = val
    },
    handleSuccess () {
      this.isBatchAdd = false
      this.getRegionHistoryList()
    },
    handleOk (row) {
      const regionData = JSON.parse(row.regionIdText)
      this.$emit('success', regionData)
      this.handleClose()
    },
    delSelectRegion () {
      this.$confirm('此操作将永久删除该记录，是否继续？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      })
        .then(() => {
          const ids = this.rowData.map(item => item.id).join(',')
          delRegionHistory(ids).then(res => {
            this.$message({
              showClose: true,
              message: '删除成功!',
              type: 'success'
            })
            this.getRegionHistoryList()
          })
        })
        .catch(() => {})
    },
    delRegion (row) {
      this.$confirm('此操作将永久删除该记录，是否继续？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      })
        .then(() => {
          const { id } = row
          delRegionHistory(id).then(res => {
            this.$message({
              showClose: true,
              message: '删除成功!',
              type: 'success'
            })
            this.getRegionHistoryList()
          })
        })
        .catch(() => {})
    },
    resetData () {
      this.page = 1
      this.search = ''
      this.rowData = []
    }
  },
  components: {
    batchAddRegion
  }
}
</script>
<style lang="scss" scoped>
a {
  color: #459ae9;
}
.button-list {
  text-align: center;
  margin-top: 20px;
}
.regions {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.region-table-expand {
  display: flex;
  justify-content: flex-start;
  align-content: flex-start;
}
.watch-allCrowd {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  max-height: 150px;
  overflow: auto;
  margin-left: 5px;
  span {
    margin-bottom: 3px;
  }
}
.region-table {
  margin-top: 20px;
  .region-table-expand {
    display: flex;
    justify-content: flex-start;
    align-content: flex-start;
    flex-direction: column;
    font-size: 14px;
    > div:nth-of-type(1) {
      color: #99a9bf;
      // width: 100px;
      margin-bottom: 10px;
    }
    .region-list {
      display: flex;
      justify-content: flex-start;
      align-content: center;
      flex-wrap: wrap;
      .region-item {
        margin: 5px;
      }
    }
    .check-more {
      margin-top: 10px;
    }
  }
}
</style>
